export var Fieldset;
(function (Fieldset) {
    Fieldset["MEMBERS"] = "members";
    Fieldset["CURRENT_MEMBER"] = "currentMember";
    Fieldset["MEMBER_GROUPS"] = "memberGroups";
    Fieldset["BADGES"] = "badges";
    Fieldset["REACTIONS"] = "reactions";
    Fieldset["REGISTRATION_DATA"] = "registrationData";
    Fieldset["MEMBER_ROLES"] = "memberRoles";
    Fieldset["MEMBER_BADGES"] = "memberBadges";
    Fieldset["TOTAL_COMMENTS"] = "totalComments";
    Fieldset["FOLLOWED_MEMBERS"] = "followedMembers";
    Fieldset["VOTES"] = "votes";
    Fieldset["CURRENT_RATING"] = "currentRating";
    Fieldset["RATINGS_SUMMARY"] = "ratingsSummary";
    Fieldset["BLOCKED_MEMBERS"] = "blockedMembers";
})(Fieldset || (Fieldset = {}));
export var RepliesFillMode;
(function (RepliesFillMode) {
    RepliesFillMode["NONE"] = "NONE";
    RepliesFillMode["FILL_TO_REPLIES_LIMIT"] = "FILL_TO_REPLIES_LIMIT";
    RepliesFillMode["FILL_TO_COMMENTS_LIMIT"] = "FILL_TO_COMMENTS_LIMIT";
})(RepliesFillMode || (RepliesFillMode = {}));
export var Ordering;
(function (Ordering) {
    Ordering["OLDEST_FIRST"] = "OLDEST_FIRST";
    Ordering["LATEST_FIRST"] = "LATEST_FIRST";
})(Ordering || (Ordering = {}));
export var ProfilePrivacyStatus;
(function (ProfilePrivacyStatus) {
    ProfilePrivacyStatus["PUBLIC"] = "PUBLIC";
    ProfilePrivacyStatus["PRIVATE"] = "PRIVATE";
})(ProfilePrivacyStatus || (ProfilePrivacyStatus = {}));
export var MemberRole;
(function (MemberRole) {
    MemberRole["FORUM_MODERATOR"] = "FORUM_MODERATOR";
    MemberRole["BLOG_WRITER"] = "BLOG_WRITER";
    MemberRole["BLOG_EDITOR"] = "BLOG_EDITOR";
    MemberRole["BLOCKED"] = "BLOCKED";
    MemberRole["OWNER"] = "OWNER";
    MemberRole["CONTRIBUTOR"] = "CONTRIBUTOR";
})(MemberRole || (MemberRole = {}));
export var SortOrder;
(function (SortOrder) {
    SortOrder["ASC"] = "ASC";
    SortOrder["DESC"] = "DESC";
})(SortOrder || (SortOrder = {}));
export var AccessStatus;
(function (AccessStatus) {
    AccessStatus["UNKNOWN"] = "UNKNOWN";
    AccessStatus["PENDING"] = "PENDING";
    AccessStatus["APPROVED"] = "APPROVED";
    AccessStatus["BLOCKED"] = "BLOCKED";
    AccessStatus["OFFLINE"] = "OFFLINE";
})(AccessStatus || (AccessStatus = {}));
